import React,{useState,useEffect} from 'react'
import style from "./Performance.module.css"
import performanceImage from "../../assests/hand-drawn-neuroeducation-illustration 4.png"
import ActiveCalendar from "../../assests/activeCalendar.png";
import ActiveWorkOut from "../../assests/ActiveWeightlifting-sport-exercise-gym (1).png";
import { Doughnut } from "react-chartjs-2";
import { Bar } from "react-chartjs-2";
import dayjs from "dayjs";
import { Line } from "react-chartjs-2";
import { baseurl } from "../Url";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import Loader from "../Loder/Loader";
import silverMedal from "../../assests/SilverPoint.png"
import bronzeMedal from "../../assests/BronzeMedal.png"
import goldMedal from "../../assests/GoldMedal.png"
import { useSelector } from "react-redux";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);



const Performance = () => {

  const key = JSON.parse(localStorage.getItem("access-token"));
  const UserID = localStorage.getItem("USERID");
  const OrgID = localStorage.getItem("ORGID");
  const categoryId = localStorage.getItem("categoryID")

  const { userDetails, status: userStatus, error: userError } = useSelector((state) => state.user);

  const [mainData,setMainData]=useState({})
  const [roundGraphData,setRoundGraphData]=useState([])
  const [learningPaceData,setLearningPaceData]=useState({})
  const [learningLevelData,setLearningLevelData]=useState({})
  const [moduleList,setModuleList]=useState([]);
  const [learningLevelModID,setLearningLevelModID]=useState("");
  const [learningPaceModID,setLearningPaceModID]=useState("");
  const [moduleProgress,setModuleProgress]=useState(0)
  const [loading,setLoading]=useState(true)
  const [todaysDailyScore,setTodaysDailyScore]=useState(""); 
  const [todaysWeeklyScore,setTodaysWeeklyScore]=useState("");  


  

  const [selectedModule, setSelectedModule] = useState(0);
  const [dailyDrillData,setDailyDrillData]=useState([])
  const [weeklyWorkoutData,setWeeklyWorkoutData]=useState([])
  const [dateForDD, setDateForDD] = useState("week");
  const [dateForWW, setDateForWW] = useState("month");
  const [dateForLP, setDateForLP] = useState("weekly");
  const [point,setPoint]=useState("");

  const [achievementDetails,setAchievementDetails] = useState([]);
  const [selectedBadge, setSelectedBadge] = useState(null);

    const handleDDDate =(date)=>{
      setDateForDD(date)
      dailyDrillGraph(date);
    }

    const handleWWDate =(date)=>{
      setDateForWW(date)
      weeklyWorkoutGraph(date);
    }
    const handleLPDate =(date)=>{
      setDateForLP(date)
     
    }

    const handleImageClick = (badgeId) => {
      setSelectedBadge(badgeId === selectedBadge ? null : badgeId); 
    };

    const getColorBasedOnPercentage = (percentage) => {
      if (percentage > 75) return "#03CD09";  
      if (percentage > 50) return "#FFCB34";   
      if (percentage > 25) return "#FF8800";   
      return "#FF2443";                         
    };

  

  const dailyLineLabels = dailyDrillData.map((item) => item["x-axis"].substring(8, 10)); 
  const firstDate = dailyDrillData.length > 0 ? dailyDrillData[0]["x-axis"] : "";
  const monthName = firstDate ? dayjs(firstDate).format('MMM') : "";
  const dailyLineScores = dailyDrillData.map((item) => item["y-axis"]);
  const ddCorrectScores = dailyDrillData.map((item) => item["correct"]); 
  const ddTimeInSeconds = dailyDrillData.map((item) => item["time_sec"]);
  

  const lineChartData = {
    labels: dailyLineLabels, 
    datasets: [
      {
        label: 'Today Scores',
        data: dailyLineScores,
        borderColor: '#595BDB',
        backgroundColor: 'rgba(89, 91, 219, 0.2)',
        fill: true,
        tension: 0.3,
        borderWidth: 2,
        pointBackgroundColor: '#595BDB',
        pointBorderColor: '#fff',
        pointBorderWidth: 2,
        pointRadius: 5,
      },
    ],
  };

  const weeklyLineLabels = weeklyWorkoutData.map((item) => item["x-axis"].substring(8, 10)); // Extracts DD from YYYY-MM-DD
  const firstWeeklyDate = weeklyWorkoutData.length > 0 ? weeklyWorkoutData[0]["x-axis"] : "";
  const weeklyMonthName = firstWeeklyDate ? dayjs(firstWeeklyDate).format('MMM') : "";
  const weeklyLineScores = weeklyWorkoutData.map((item) => item["y-axis"]);
  const wwCorrectScores = weeklyWorkoutData.map((item) => item["correct"]); // Array of correct scores
  const wwTimeInSeconds = weeklyWorkoutData.map((item) => item["time_sec"]);

  const weeklyLineChart = {
    labels: weeklyLineLabels,
    datasets: [
      {
        label: 'Weekly Scores',
        data: weeklyLineScores,
        borderColor: '#29bcd6',
        backgroundColor: '#7adef0',
        fill: true,
        tension: 0.3, 
        borderWidth: 2,
        pointBackgroundColor: '#29bcd6',
        pointBorderColor: '#fff',
        pointBorderWidth: 2,
        pointRadius: 5,
      },
    ],
  };
  

  const learningPaceLabels = learningPaceData?.data?.map(item => 
    dateForLP === "yearly" ? dayjs(item.period).format('MMM') : dayjs(item.period).format('DD')
  );
  
  const firstLLDate = learningPaceData?.data?.length > 0 ? learningPaceData?.data[0]["period"] : "";
  const lLMonthName = firstLLDate ? dayjs(firstLLDate).format('MMM') : "";
  
  const topicCounts = learningPaceData?.data?.map(item => item.topic_count); 
  
  const learningPaceChartData = {
      labels: learningPaceLabels, 
      datasets: [
          {
              label: dateForLP === "yearly" ? 'Monthly Topic Count' : 'Daily Topic Count', 
              data: topicCounts,
              backgroundColor: '#FF8800',
              barThickness: 20,
              borderRadius: 10,
          }
      ]
  };

const learningPaceOptions = {
  responsive: true,
  plugins: {
      legend: {
          display: false,
      },
      tooltip: {
          callbacks: {
              label: function (context) {
                  return `Topic ${context.dataIndex + 1}: ${context.raw}`;
              },
          },
      },
  },
  scales: {
      x: {
        title: {
          display: true,
          text: dateForLP === "yearly" ? 'Month' : `Day (${lLMonthName})`,
      },
          grid: {
              display: false,
          },
          beginAtZero: false,
          ticks: {
              stepSize: 1,
          },
      },
      y: {
          title: {
              display: true,
              text: 'Total no.of topics',
          },
          beginAtZero: true,
          max: learningPaceData.module_topics_count, 
          ticks: {
              stepSize: 1, 
              callback: function(value) {
                  return value; 
              },
          },
      },
  },
};

const learningLevelGraphData = {
  labels: [...Array(learningLevelData?.module_topics_count).keys()], 
  datasets: [
    {
      label: 'Baseline Score',
      data: learningLevelData?.data?.map((topicArray) => {
        const baselineData = topicArray.find(topic => topic.score_level === 'baseline');
        return baselineData ? parseFloat(baselineData.score) : null;
      }), 
      backgroundColor: '#595BDB', 
      barThickness: 20,
      borderRadius: 10, 
    },
    {
      label: 'Current Score',
      data: learningLevelData?.data?.map((topicArray) => {
        const currentData = topicArray.find(topic => topic.score_level === 'current');
        return currentData ? parseFloat(currentData.score) : null;
      }), 
      backgroundColor: '#FFBE00', 
      barThickness: 20,
      borderRadius: 10, 
    },
  ],
};

const learningLevelOptions = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        title: function (context) {
          const topicIndex = context[0].dataIndex + 1;
          return `Topic ${topicIndex}`;
        },
        label: function (context) {
          return `${context.dataset.label}: ${context.raw}%`;
        },
      },
    },
  },
  scales: {
    x: {
      title: {
        display: true,
        text: 'Topics',
      },
      grid: {
        display: false,
      },
      ticks: {
        stepSize: 1,
        callback: function (value) {
          return value + 1; 
        },
      },
    },
    y: {
      title: {
        display: true,
        text: 'Score (%)',
      },
      beginAtZero: true,
      max: 100, 
      ticks: {
        stepSize: 20,
        callback: function (value) {
          return value + '%'; 
        },
      },
    },
  },
};



  async function mainAnalytics() {
    await fetch(`${baseurl}/performance/analytics/main`, {
      method: "POST",
      body: JSON.stringify({ 
        user_id: UserID,
        org_id:OrgID,
        category_id:categoryId
       }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
         setMainData(data.data[0])
         const initialModuleId=data.data[0]?.Modules[0].module_id
         topicAnalytics(initialModuleId)
         setLearningPaceModID(initialModuleId)
         setLearningLevelModID(initialModuleId)
         setModuleList(data.data[0]?.Modules)
         setTimeout(() => {
          setLoading(false)
         }, 2000);
        }
        else{
          setMainData({})
        }
      });
  }

  async function topicAnalytics(modID) {
    await fetch(`${baseurl}/performance/module-topic-summary`, {
      method: "POST",
      body: JSON.stringify({ 
        user_id: UserID,
        module_id:modID
       }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
         setRoundGraphData(data.data)
         setModuleProgress(data.module_progress)
        }
        else{
          setRoundGraphData([])
          setModuleProgress(0)
        }
      });
  }
  async function learningPace(modID,date) {
    await fetch(`${baseurl}/performance/topic-count`, {
      method: "POST",
      body: JSON.stringify({ 
        user_id: UserID,
        module_id:modID,
        filter: date
       }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
         setLearningPaceData(data)
        }
        else{
          setLearningPaceData(data)
        }
      });
  }
  async function learningLevel(modID,) {
    await fetch(`${baseurl}/performance/learning-level`, {
      method: "POST",
      body: JSON.stringify({ 
        user_id: UserID,
        module_id:modID,
       }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
         setLearningLevelData(data)
        }
        else{
          setLearningLevelData()
        }
      });
  }

  async function dailyDrillGraph(type) {
    await fetch(`${baseurl}/analytics/scores/daily`, {
      method: "POST",
      body: JSON.stringify({ 
        user_id: UserID,
        filter_type:type
       }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
         setDailyDrillData(data.data)
         setTodaysDailyScore(data.todays_score)
        }
        else{
          setDailyDrillData([])
        }
      });
  }
  async function weeklyWorkoutGraph(type) {
    await fetch(`${baseurl}/analytics/scores/weekly`, {
      method: "POST",
      body: JSON.stringify({ 
        user_id: UserID,
        filter_type:type
       }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
         setWeeklyWorkoutData(data.data)
         setTodaysWeeklyScore(data.todays_score)
        }
        else{
          setWeeklyWorkoutData([])
        }
      });
  }

  async function badgeGet() {
    const body = {
      user_id: UserID,
    };

    await fetch(`${baseurl}/user_badges`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          const topicAndModule = data.data?.Topic_and_module?.filter(item => item.status === true) || [];
          const dailyDrill = data.data?.Daily_Drill?.filter(item => item.status === true) || [];
          const weeklyWorkout = data.data?.Weekly_Workout?.filter(item => item.status === true) || [];

          const sortedData = [...topicAndModule, ...dailyDrill, ...weeklyWorkout];
          setAchievementDetails(sortedData)
          
          
        }
      });
  }

  useEffect(()=>{
    if(categoryId){
      mainAnalytics();
      dailyDrillGraph("week");
      weeklyWorkoutGraph("month");
      badgeGet();
    }
  },[categoryId])

  useEffect(()=>{
    if(learningPaceModID){
    learningPace(learningPaceModID,dateForLP)
    }
  },[learningPaceModID,dateForLP])

  useEffect(()=>{
    if(learningLevelModID){
    learningLevel(learningLevelModID)
    }
  },[learningLevelModID])

  useEffect(()=>{

    if(userDetails&&userDetails.Rewards){
      setPoint(userDetails&&userDetails.Rewards?.Badge)
    }
    else{
      setPoint("")
    }
  },[userDetails])


  return (
    <div className={style.container}>
      {loading?<Loader/>:""}
        <div className={style.performance}>
        <div className={style.welcomeSection}>
          <div className={style.welcomeSectionLeft}>
            <p>My Dashboard</p>
            <span>See how far you’ve come and where you need more practice</span>
          </div>
          <div className={style.welcomeSectionRight}>
            <div className={style.welcomeImageContainer}>
              <div className={style.welcomeImage}>
                <img src={performanceImage} alt="welcome" />
              </div>
            </div>
          </div>
        </div>

        <div className={style.assessmentSection}>
        {point!==""?
          <div className={style.userPointCard}>
                <img src={point==="Silver"?
                  silverMedal
                  :point==="Bronze"?bronzeMedal
                  :point==="Gold"?goldMedal
                  :""} alt="avatar" />
          </div>
          :null}
            <div className={style.dailyDrillCard}>
            <div className={style.assessmentCardLeft}>
                <div className={style.assessmentCardImage}>
                    <img src={ActiveCalendar} alt='dailyDrill'/>
                </div>
            </div>
            <div className={style.assessmentCardRight}>
                <p>You’ve completed </p>
                <h4>{mainData.Completed_Drills?.completed_daily||0}</h4>
                <span>Daily drills </span>
            </div>
            </div>

            <div className={style.weeklyWorkOutCard}>
            <div className={style.assessmentCardLeft}>
                <div className={style.assessmentCardImage}>
                    <img src={ActiveWorkOut} alt='weeklyWorkout'/>
                </div>
            </div>
            <div className={style.assessmentCardRight}>
                <p>You’ve completed </p>
                <h4>{mainData.Completed_Drills?.completed_weekly||0}</h4>
                <span>Weekly Workouts </span>
            </div>
            </div>
        </div>

        <div className={style.moduleSection}>
            <div className={style.moduleHeder}>
                <p>Modules</p>
            </div>
            <div className={style.numberBarSection}>
                <div className={style.numberBar}>
                  {mainData.Modules&&mainData.Modules.length>0&&mainData.Modules.map((module, index) => (
                    <div key={module.module_id} className={ selectedModule === index?style.numberContainer:""}>
                      <div
                        key={module.module_id}
                        className={
                          selectedModule === index
                            ? style.selectedNumber
                            : style.number
                        }
                        onClick={() => {
                          setSelectedModule(index)
                          topicAnalytics(module.module_id)
                        }}
                      >
                        {index + 1}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className={style.moduleSubHeder}>
              {mainData.Modules?.length > 0 && mainData.Modules[selectedModule] ? (
                    <p>
                      Module progress:{" "}
                      <span>{mainData.Modules[selectedModule]?.module_name}</span>
                    </p>
                  ) : (
                    <p>Module progress: <span>No Module Selected</span></p>
                  )}
            </div>
            <div className={style.progressBarSection}>
            <div
              className={style.progressBar}
              style={{
                
                backgroundColor: "#8E88903D",
                borderRadius: "2rem",
              }}
            >
              <div
                style={{
                  width: `${moduleProgress}%`,
                  backgroundColor: "#595BDB",
                  height: "12px",
                  transition: "width 0.5s ease-in-out",
                  borderRadius: "1rem",
                }}
              ></div>
            </div>
            <div className={style.progressBarCount}>
            <p>{Math.round(moduleProgress)}%</p>
            </div>
          </div>
            <div className={style.roundGraphSection}>
              {roundGraphData.length>0&&roundGraphData.map((data,i)=>{
                const color = getColorBasedOnPercentage(data.percentage_correct);
                const chartData = {
                  labels: ["Achieved", "Remaining"],
                  datasets: [
                    {
                      data: [data.percentage_correct, 100 - data.percentage_correct], 
                      backgroundColor: [color, "#EAEAEA"],   
                      borderWidth: 0,
                    },
                  ],
                };
      
                const options = {
                  cutout: '80%', 
                  plugins: {
                    legend: {
                      display: false, 
                    },
                    tooltip: {
                      enabled: false, 
                    },
                  },
                };
                return(
                <div key={i} className={style.roundGraphCard}>
                <div className={style.roundGraphCardHeder}>
                  <p>Topic {i+1}</p>
                </div>
                <div className={style.roundGraph}>
                <Doughnut data={chartData} options={options} />
                <div className={style.roundGraphData}>
                  <p>{data.correct_answers}</p>
                </div>
                </div>
  
                <div className={style.roundGraphCardBottom}>
                  <p>{Math.round(data.percentage_correct)}%</p>
                </div>
                </div>
                )
              })}
            </div>
        </div>
        <div className={style.learningSection}>
        <div className={style.learningSectionLeft}>
          <div className={style.learningHeader}>
            <p>Learning Pace</p>
          </div>
          <div className={style.learningSubHeader}  >
          <div className={style.learningSubHeaderLeft}>
           <div className={`${style.subHeaderCard} ${dateForLP==="weekly"?style.selectedSubHeaderCard:""}`}
            onClick={() => handleLPDate("weekly")}>
            <p>Week</p>
           </div>
           <div className={`${style.subHeaderCard} ${dateForLP==="monthly"?style.selectedSubHeaderCard:""}`}
           onClick={() => handleLPDate("monthly")}>
            <p>Month</p>
           </div>
           <div className={`${style.subHeaderCard} ${dateForLP==="yearly"?style.selectedSubHeaderCard:""}`}
           onClick={() => handleLPDate("yearly")}>
            <p>Year</p>
           </div>
           </div>
           <div className={style.learningSubHeaderRight}>
           <select
           value={learningPaceModID}
           onChange={(e)=>setLearningPaceModID(e.target.value)}
           >
           {moduleList && moduleList.length > 0 &&
            moduleList.map((mod, index) => (
              <option key={mod.module_id} value={mod.module_id}>
                {`Module ${index + 1}`}
              </option>
            ))}
           </select>
           </div>
          </div>
          
          <div className={style.barGraph}>
          <Bar data={learningPaceChartData} options={learningPaceOptions} />
          </div>
        </div>
        <div className={style.learningSectionRight}>
        <div className={style.learningHeader}>
            <p>Learning Level</p>
          </div>
          <div className={style.learningSubHeader}>
          <div className={style.levelHeader}>
          <div className={style.levelHeaderCard}>
            <span></span>
            <h4>Current score</h4>
            <p>(The mark from your last attempt)
            </p>
          </div>
          <div className={style.levelHeaderCard}>
            <span></span>
            <h4>Baseline score</h4>
            <p>(the mark from your first attempt)
            </p>
          </div>
          </div>
           <div className={style.learningSubHeaderRight}>
           <select
          value={learningLevelModID}
          onChange={(e) => setLearningLevelModID(e.target.value)}
        >
          {moduleList && moduleList.length > 0 &&
            moduleList.map((mod, index) => (
              <option key={mod.module_id} value={mod.module_id}>
                {`Module ${index + 1}`}
              </option>
            ))}
        </select>

           </div>
          </div>
          <div className={style.barGraph}>
          <Bar
            data={learningLevelGraphData}
            options={learningLevelOptions}
          />
          </div>
        </div>
        </div>

        <div className={style.assessmentSection}>
        <div className={style.assessmentSectionLeft}>
        <div className={style.learningHeader}>
            <p>Daily drill</p>
          </div>
          <div className={style.drillHeader}>
          <div className={style.learningSubHeaderLeft}>
           <div className={`${style.subHeaderCard} ${dateForDD==="week"?style.selectedSubHeaderCard:""}`}
           onClick={() => handleDDDate("week")}>
            <p>Week</p>
           </div>
           <div className={`${style.subHeaderCard} ${dateForDD==="month"?style.selectedSubHeaderCard:""}`}
           onClick={() => handleDDDate("month")}>
            <p>Month</p>
           </div>
           <div className={`${style.subHeaderCard} ${dateForDD==="year"?style.selectedSubHeaderCard:""}`}
           onClick={() => handleDDDate("year")}>
            <p>Year</p>
           </div>
           </div>
           <div className={style.learningSubHeaderRight}>
            <div className={style.assessmentScore}>
              <p>Today’s <br/>
              Score</p>
              <h4>{todaysDailyScore}</h4>
            </div>
           </div>
          </div>
          <div className={style.lineGraph}>
          <Line
              data={lineChartData}
              options={{
                responsive: true,
                plugins: {
                  legend: {
                    display: false,
                  },
                  tooltip: {
                    callbacks: {
                      label: function (context) {
                        const index = context.dataIndex;
                        const score = ddCorrectScores[index];
                        const timeSec = ddTimeInSeconds[index];
                        const timeMin = Math.floor(timeSec / 60);
                      
                        // Format time display without seconds
                        const timeDisplay = timeMin > 0 
                          ? `${timeMin} mins`
                          : `< 1 min`;
                      
                        return `You scored ${score} in ${timeDisplay}`;
                      }
                      
                    },
                  },
                },
                scales: {
                  x: {
                    type: 'category', 
                    title: {
                      display: true,
                      text: dateForDD === "year" ? "Month" : "Day", 
                    },
                    grid: {
                      display: false,
                    },
                    beginAtZero: false,
                    offset:true,
                    ticks: {
                      callback: function(value, index) {
                        return dailyLineLabels[index]; 
                      },
                    },
                  },
                  y: {
                    title: {
                      display: true,
                      text: 'Score (%)',
                    },
                    grid: {
                      display: false,
                    },
                    beginAtZero: true,
                    min: 0,
                    max: 100,
                    ticks: {
                      stepSize: 20,
                    },
                  },
                },
              }}
            />

          </div>
        </div>
        <div className={style.assessmentSectionRight}>
        <div className={style.learningHeader}>
            <p>Weekly workout</p>
          </div>
          <div className={style.drillHeader}>
          <div className={style.learningSubHeaderLeft}>
           
           <div className={`${style.subHeaderCard} ${dateForWW==="month"?style.selectedSubHeaderCard:""}`}
           onClick={() => handleWWDate("month")}>
            <p>Month</p>
           </div>
           <div className={`${style.subHeaderCard} ${dateForWW==="year"?style.selectedSubHeaderCard:""}`}
           onClick={() => handleWWDate("year")}>
            <p>Year</p>
           </div>
           </div>
           <div className={style.learningSubHeaderRight}>
            <div className={style.assessmentScore} style={{backgroundColor:"#00DFC7"}}>
              <p>Week's <br/>
              Score</p>
              <h4>{todaysWeeklyScore}</h4>
            </div>
           </div>
          </div>

          <div className={style.lineGraph}>
          <Line
              data={weeklyLineChart}
              options={{
                responsive: true,
                plugins: {
                  legend: {
                    display: false,
                  },
                  tooltip: {
                    callbacks: {
                      label: function (context) {
                        const index = context.dataIndex;
                        const score = wwCorrectScores[index];
                        const timeSec = wwTimeInSeconds[index];
                        const timeMin = Math.floor(timeSec / 60);
                        const timeDisplay = timeMin > 0 
                          ? `${timeMin} mins`
                          : `< 1 min`;
                      
                        return `You scored ${score} in ${timeDisplay}`;
                      }
                      
                    },
                  },
                },
                scales: {
                  x: {
                    title: {
                      display: true,
                      text: dateForWW === "year" ? "Month" : "Day", 
                    },
                    grid: {
                      display: false,
                    },
                    offset:true,
                  },
                  y: {
                    title: {
                      display: true,
                      text: 'Score (%)',
                    },
                    grid: {
                      display: false,
                    },
                    beginAtZero: true,
                    min: 0,
                    max: 100,
                    ticks: {
                      stepSize: 20,
                    },
                  },
                },
              }}
            />
          </div>
        </div>
        </div>
             {achievementDetails&&achievementDetails.length>0? 
              <>
          <div className={style.achievementHeader}>
              <p>My Badges</p>
            </div>
            <div className={style.achievementSection}>
            <div className={style.achievementGrid}>
                {achievementDetails&&achievementDetails.length>0&&achievementDetails.map((data)=>(
                   <div key={data.badge_id} className={style.achievementCard}>
                   <div 
                    className={`${style.achievementCardImage} ${
                      selectedBadge === data.badge_id ? style.selectedImage : ""
                    }`}
                    onClick={() => handleImageClick(data.badge_id)}
                  >
                     <img src={data.active_img} alt="img"
                     />
                   </div>
                   <div className={style.achievementCardText}>
                     <p>{data.name} </p>
                     {selectedBadge === data.badge_id && (
                      <span>{data.description}</span>
                    )}
                   </div>
                   </div>
                ))}
               
              </div>
              <div className={style.viewText}>
                <p>View all</p>
              </div>
              </div>
              </>
              :""}
              <div className={style.recommendedText}>
                <p><span>*</span>Recommended timeframes for optimum results are:<br/>
                Completion of Modules 1, 2 and 5 within six weeks; Module 3 within 10 weeks; Module 4 within 12 weeks; Modules 6 and 7 within four weeks</p>
              </div>
        </div>
    </div>
  )
}

export default Performance